import { getWechatConfig } from "@/api/api";
export const wxConfig = {
	data() {
		return {
			
		}
	},
	mounted(){
		
	},
	methods: {
		async receiveConfig(){
			try{
				const resCfg = await getWechatConfig({
					url: encodeURIComponent(location.href)
				})
				wx.config({
					debug: false,
				    appId: resCfg.data.resBody.appId,
				    timestamp: resCfg.data.resBody.timestamp,
				    nonceStr: resCfg.data.resBody.nonceStr,
				    signature: resCfg.data.resBody.signature,
				    jsApiList: ['hideAllNonBaseMenuItem','hideOptionMenu']
				});
				wx.ready(function() {
					wx.checkJsApi({
						jsApiList: ['hideAllNonBaseMenuItem','hideOptionMenu'],
						success:function(res){
							wx.hideOptionMenu();
							wx.hideAllNonBaseMenuItem();
						}	
					})
				});
				wx.error(function(err){
				    console.log(err);
				});
			}catch(e){
				console.log(e);
			}
		},
	}
}