// 引入封装好的axios(ax.js)
import instance from "@/utils/axios";
import instanceApp from "@/utils/axios_app";

// 对应axios方法封装
// 导出一个使用axios发请求的函数即可
export default (url, method, data, Content_Type) => {
  // 使用配置好的axios来帮你发送请求，得到数据（返回promise）
  // 快捷调用 axios.get() axios.post() ...  完整调用 axios({请求配置})
  // 配置选项有：url 地址 method 请求方式 params get传参 data 其他方式传参 headers 头部传参
  return instance({
    url,
    method,
    // data 提交给后台的参数（params|data）选项需要判断
    // 往对象中动态插入属性  obj.name = 10  obj[变量|js表达式] = 10
    // 用户传入：get Get GET  处理：转换小写进行判断
    [method.toLowerCase() === "get" ? "params" : "data"]: data,
  });
};

/* 扫码出入场 */
/**
 * 检查车道是否有车
 * @param park_code
 * @param lane_code
 * @param inout_type
 * @param user_agent
 * @returns {*}
 */
export const checkForCarAPI = ({
  park_code,
  lane_code,
  inout_type,
  user_agent,
}) =>
  instance({
    url: `/Park-PayServer/QRServer/do_QRAccess?park_code=${park_code}&lane_code=${lane_code}&inout_type=${inout_type}&user_agent=${user_agent}`,
    method: "GET",
  });

/**
 * 把code传给后台
 * @param park_code
 * @param lane_code
 * @param inout_type
 * @param pay_kind
 * @param auth_code
 * @returns {*}
 */
export const sendCodeAPI = ({
  park_code,
  lane_code,
  inout_type,
  pay_kind,
  auth_code,
}) =>
  instance({
    url: `/Park-PayServer/QRServer/scanQRCode?park_code=${park_code}&lane_code=${lane_code}&inout_type=${inout_type}&pay_kind=${pay_kind}&auth_code=${auth_code}`,
    method: "GET",
  });

/**
 * 出场点击去支付调起支付
 * @param user_id
 * @returns {*}
 */
export const toPayAPI = ({ user_id }) =>
  instance({
    url: `/Park-PayServer/QRServer/toPay?user_id=${user_id}`,
    method: "GET",
  });

/* 扫码购买月租车 */
/**
 * 上传行驶证
 * @param data
 * @returns {*}
 */
export const uploadDrivingLicense = (data) => {
  return instance({
    url: "/Park-CarMngServer/drivingLicense/file-upload-drivingLicense",
    method: "POST",
    data,
  });
};

/**
 * 获取app_id
 * @param domain_code
 * @param park_code
 * @param user_agent
 * @returns {*}
 */
export const getAppId = ({ park_code, user_agent, domain_code }) => {
  return instance({
    url: `/Park-PayServer/MonthCarQRServer/do_QRAccess?domain_code=${domain_code}&park_code=${park_code}&user_agent=${user_agent}`,
    method: "GET",
  });
};

/**
 * 根据停车场和区域获取月租配置
 * @param data
 * @returns {*}
 */
export const getMonthCarConfig = (data) => {
  return instance({
    url: "/Park-VASServer/LongLeaseMng/do_s_longLeaseListForgzh",
    method: "POST",
    data,
  });
};

/**
 * 检查产品
 * @param code
 * @param park_code
 * @param domain_code
 * @param pay_kind
 * @returns {*}
 */
export const checkForProduction = ({
  code,
  park_code,
  domain_code,
  pay_kind,
}) => {
  return instance({
    url: `/Park-PayServer/MonthCarQRServer/scanQRCode?auth_code=${code}&park_code=${park_code}&domain_code=${domain_code}&pay_kind=${pay_kind}`,
    method: "GET",
  });
};

/**
 * 获取月租车配置列表
 * @param data
 * @returns {*}
 */
export const getConfigList = (data) => {
  return instance({
    url: "/Park-PayServer/monthCar/do_s_longLeaseListForgzh",
    method: "POST",
    data,
  });
};

/**
 * 月租车立即购买生成订单
 * @param data
 * @returns {*}
 */
export const monthCarToPay = (data) => {
  return instance({
    url: `/Park-PayServer/MonthCarQRServer/toPay`,
    method: "POST",
    data,
  });
};

/**
 * 扫码领取优惠券
 * @param data
 * @returns {*}
 */
export const receiveCouponAPI = (data) =>
  instance({
    url: "/Park-PayServer/CouponQRServer/do_m_userGetCouponH5",
    method: "POST",
    data,
  });

/**
 * 用code换openid
 * @param data
 * @returns {*}
 */
export const getOpenIdByCode = (data) => {
  return instance({
    url: "/Park-PayServer/CouponQRServer/getOpenIdByCode",
    method: "POST",
    data,
  });
};

/**
 * 二维码是否失效
 * @param data
 * @returns {*}
 */
export const qrCodeTempAPI = (data) =>
  instance({
    url: "/Park-CouponServer/Coupon/scanQRCodeTemp",
    method: "POST",
    data,
  });
/**
 * 领取优惠券前用户授权认证
 * @param id
 * @param park_code
 * @param user_agent
 * @param ran_str
 * @param merchants_id
 * @returns {*}
 */
export const beforeReceiveAuth = ({
  id,
  park_code,
  user_agent,
  ran_str,
  merchants_id,
}) => {
  return instance({
    url: `/Park-PayServer/CouponQRServer/do_QRAccess?id=${id}&park_code=${park_code}&user_agent=${user_agent}&ran_str=${ran_str}&merchants_id=${merchants_id}`,
    method: "GET",
  });
};

/**
 * 静态码扫码
 * @param park_code
 * @param user_agent
 * @param ran_str
 * @param batch_number
 * @returns {*}
 */
export const staticCode = ({
  park_code,
  user_agent,
  ran_str,
  batch_number,
}) => {
  return instance({
    url: `/Park-PayServer/CouponQRServer/do_QRAccess?park_code=${park_code}&user_agent=${user_agent}&ran_str=${ran_str}&batch_number=${batch_number}`,
    method: "GET",
  });
};

/**
 * 动态码扫码
 * @param id
 * @param park_code
 * @param user_agent
 * @returns {*}
 */
export const dynamicCode = ({ id, park_code, user_agent }) => {
  return instance({
    url: `/Park-PayServer/CouponQRServer/do_QRAccess?id=${id}&park_code=${park_code}&user_agent=${user_agent}`,
    method: "GET",
  });
};

/**
 * 使用优惠券支付
 * @param data
 * @returns {*}
 */
export const useCouponToPay = (data) => {
  return instance({
    url: "/Park-CouponServer/Coupon/do_u_userCoupontoPay",
    method: "POST",
    data: {
      header: {
        from: "",
        msg_code: "MSG_O_COUPON_COUPONPAY",
        token: "",
      },
      reqBody: {
        ...data,
      },
    },
  });
};

/**
 * 场内支付获取appid
 * @param park_code
 * @param user_agent
 * @returns {*}
 */
export const payInsideGetAppid = ({ park_code, user_agent }) => {
  return instance({
    url: `/Park-PayServer/ParkInPaymentQRServer/do_QRAccess?park_code=${park_code}&user_agent=${user_agent}`,
    method: "GET",
  });
};

/**
 * 场内支付获取订单
 * @param data
 * @returns {*}
 */
export const payInsideCheckOrder = (data) => {
  return instance({
    url: "/Park-FzytAppServer/api/selectParkPay",
    method: "POST",
    data,
  });
};

/**
 * 场内支付去支付
 * @param data
 * @returns {*}
 */
export const payInsideToPay = (data) => {
  return instance({
    url: "/Park-FzytAppServer/api/pay",
    method: "POST",
    data,
  });
};

/**
 * 根据openid换token
 * @param data
 * @returns {*}
 */
export const getTokenBuyOpenid = (data) => {
  return instance({
    url: "/Park-FzytAppServer/api/getWxgzhUserByOpenId",
    method: "POST",
    data,
  });
};

/**
 * 获取微信公众号基本信息
 * @param url
 * @returns {*}
 */
export const getWechatConfig = ({ url }) => {
  return instance({
    url: `/Park-FzytAppServer/api/pubCar/getWxConfig?url=${url}`,
    method: "GET",
  });
  /*return instanceApp({
    url: `/api/pubCar/getWxConfig?url=${url}`,
    method: "GET",
  });*/
};

/**
 * 聚合码获取appid
 * @param park_code
 * @param user_agent
 * @returns {*}
 */
export const aggregateGetAppid = ({ park_code, user_agent }) => {
  return instance({
    url: `/Park-PayServer/orderIntegration/do_QRAccess?park_code=${park_code}&user_agent=${user_agent}`,
    method: "GET",
  });
};

/**
 * 聚合码获取openid
 * @param park_code
 * @param auth_code
 * @param pay_kind
 * @returns {*}
 */
export const aggregateGetOpenid = ({ park_code, auth_code, pay_kind }) => {
  return instance({
    url: `/Park-PayServer/orderIntegration/scanQRCode?park_code=${park_code}&auth_code=${auth_code}&pay_kind=${pay_kind}`,
    method: "GET",
  });
};

/**
 * 聚合支付
 * @param data
 * @returns {*}
 */
export const aggregatePay = (data) => {
  return instance({
    url: "/Park-PayServer/orderIntegration/toPay",
    method: "POST",
    data,
  });
};

/**
 * 云端续费获取appid
 * @param park_code
 * @param user_agent
 * @returns {*}
 */
export const monthRenewGetAppid = ({ park_code, user_agent }) => {
  return instance({
    url:
      "/Park-PayServer/MonthCarPayment/do_QRAccess?park_code=" +
      park_code +
      "&user_agent=" +
      user_agent,
    method: "GET",
  });
};

/**
 * 云端续费获取openid
 * @param park_code
 * @param auth_code
 * @param pay_kind
 * @param order_code
 * @returns {*}
 */
export const monthRenewGetOpenid = ({
  park_code,
  auth_code,
  pay_kind,
  order_code,
}) => {
  return instance({
    url:
      "/Park-PayServer/MonthCarPayment/scanQRCode?park_code=" +
      park_code +
      "&auth_code=" +
      auth_code +
      "&pay_kind=" +
      pay_kind +
      "&order_code=" +
      order_code,
    method: "GET",
  });
};

/**
 * 云端续费支付
 * @param data
 * @returns {*}
 */
export const monthRenewToPay = (data) => {
  return instance({
    url: "/Park-PayServer/MonthCarPayment/toPay",
    method: "POST",
    data,
  });
};
